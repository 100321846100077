<template>
  <div class="goodsedit">
    <van-nav-bar
      title="价格修改"
      right-text="按钮"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      fixed
      placeholder
    >
    <template #right>
      <van-button
        size="mini"
        round
        type="danger"
        text="保存"
      />
    </template>
    </van-nav-bar>
    <div class="main">
      <van-cell class="goods_title" :border="false">
        <template #title>
          {{title}}
        </template>
      </van-cell>
      <div class="sku_main">
        <van-tabs v-model="active" :swipe-threshold="list.length">
          <van-tab v-for="(item, index) in list" :key="index" :title="item.levelname">
            <van-cell class="batch_edit" :border="false" title="最终售价=售价" center>
              <template #right-icon>
                <van-button
                  text="批量修改"
                  type="info"
                  plain
                  round
                  size="mini"
                  @click="showbatch"
                />
              </template>
            </van-cell>
            <van-cell
              class="edit_title"
              title="SKU名称"
              value="最低价"
              center
            >
              <template #right-icon>
                <div class="merch_jiajia">售价</div>
              </template>
            </van-cell>
            <van-cell
              v-for="(val, key) in item.list"
              :key="key"
              class="edit_title"
              :value="val.price"
              center
            >
              <template #title>
                <div @click="showTitle(val.name)" class="van-ellipsis">
                  {{val.name}}
                </div>
              </template>
              <template #right-icon>
                <div class="merch_jiajia">
                  <van-stepper
                    :default-value="val.salse"
                    :min="val.price"
                    :max="val.price*2"
                    input-width="40"
                    button-size="34"
                    v-model="val.salse"
                    @change="salsePriceChange"
                    :name="key"
                  />
                </div>
              </template>
            </van-cell>
          </van-tab>
        </van-tabs>
      </div>
      <van-dialog
        v-model="BatchEditShow"
        title=""
        show-cancel-button
        @confirm="setbatchmrice"
        @cancel="cancelbatchmprice"
      >
        <van-cell :border="false">
          <template #title>
            <div>批量调价</div>
          </template>
          <template #label>
            <div>确认后该级别下所有价格将统一设置</div>
          </template>
        </van-cell>
        <van-cell :border="false">
          <template #title>
            <div>基础价</div>
          </template>
          <template #default>
            <div class="red">&yen;{{batcholdprice[0]}} ~ &yen;{{batcholdprice[1]}}</div>
          </template>
        </van-cell>
        <van-cell :border="false" center>
          <template #title>
            <div>加价</div>
          </template>
          <template #default>
            <div>
              <van-stepper
                default-value="0"
                min="0"
                input-width="40"
                button-size="34"
                v-model="batchMprice"
                @change="batchChange"
              />
            </div>
          </template>
          <template #right-icon><div style="margin-right: 10px;">元</div></template>
        </van-cell>
        <van-cell :border="false" center>
          <template #title>
            <div>加价</div>
          </template>
          <template #default>
            <div>
              <van-stepper
                default-value="0"
                min="0"
                input-width="40"
                button-size="34"
                v-model="batchMprice2"
                @change="batchChange2"
              />
            </div>
          </template>
          <template #right-icon><div style="margin-right: 10px;">%</div></template>
        </van-cell>
        <van-cell :border="false">
          <template #title>
            <div>最终价</div>
          </template>
          <template #default>
            <div class="red">&yen;{{batchnewprice[0]}} ~ &yen;{{batchnewprice[1]}}</div>
          </template>
        </van-cell>
      </van-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "GoodsEdit",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      id: 0, // 产品id
      title: "产品标题",
      active: 0,
      list: [],
      merchprice: [],
      BatchEditShow: false,
      batchMprice: 0,
      batchMprice2: 0,
      batcholdprice: [],
      batchnewprice: [],
      type: 0, // 1是直接加价，2是加百分比
    }
  },
  mounted() {
    console.log("sss");
    this.id = this.$route.query.id;
    this.init(); // 获取初始数据
  },
  methods: {
    init() {
      this.getsku();
    },
    getsku() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/get_sku_list",
          that.$qs.stringify({
            id: that.id
          })
        )
        .then(res => {
          console.log(res);
          if(res.data.code == 100000) {
            that.title = res.data.data.title;
            that.list = res.data.data.option;
            that.merchprice = res.data.data.merchprice;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 保存按钮
    onClickRight() {
      console.log("保存");
      console.log(this.list);
      let that = null;
      that = this;
      
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/set_sku",
          that.$qs.stringify({
            list: that.list,
            id: that.id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "保存成功");
            setTimeout(() => {
              that.onClickLeft();
            }, 1500);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "保存失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 显示完整标题
    showTitle(title) {
      this.$toast(title);
    },
    // 显示批量修改弹框
    showbatch() {
      console.log(this.list[this.active].list);
      let list = null;
      const arr = [];
      list = this.list[this.active].list;
      list.forEach(item => {
        arr.push(item.price);
      })
      const max = Math.max.apply(null, arr);
      const min = Math.min.apply(null, arr);
      this.batcholdprice = [min,max];
      if (this.type == 0) {
        this.batchnewprice = [min, max];
      }
      if (this.type == 1) {
        this.batchnewprice = [min + this.batchMprice, max + this.batchMprice];
      }
      if (this.type == 2) {
        this.batchnewprice = [min * (this.batchMprice2/100+1), max * (this.batchMprice2/100+1)];
      }
      
      console.log(max, min);
      this.BatchEditShow = true;
    },
    // 批量修改弹框中价格发生变化时
    batchChange(num) {
      console.log(num);
      this.batchnewprice[0] = (parseFloat(this.batcholdprice[0]) + parseFloat(num)).toFixed(2);
      this.batchnewprice[1] = (parseFloat(this.batcholdprice[1]) + parseFloat(num)).toFixed(2);
      this.batchMprice = num;
      this.batchMprice2 = 0;
      this.type = 1;
    },
    // 批量修改价格 百分比
    batchChange2(num) {
      this.batchMprice = 0;
      this.batchMprice2 = num;
      this.batchnewprice[0] = (parseFloat(this.batcholdprice[0])*(num/100+1)).toFixed(2);
      this.batchnewprice[1] = (parseFloat(this.batcholdprice[1])*(num/100+1)).toFixed(2);
      this.type = 2;
    },
    // 确认批量修改价格
    setbatchmrice() {
      let price = 0;
      if (parseFloat(this.batchMprice) > 0) {
        price = parseFloat(this.batchMprice);
      }
      if (parseFloat(this.batchMprice2) > 0) {
        price = parseFloat(this.batchMprice2);
      }
      this.list[this.active].list.forEach((item, index) => {
        if (this.type == 1) {
          this.list[this.active].list[index].mprice = price.toFixed(2);
          this.list[this.active].list[index].salse = (price + item.price).toFixed(2);
        }
        if (this.type == 2) {
          this.list[this.active].list[index].mprice = (item.price*(price/100+1)).toFixed(2);
          this.list[this.active].list[index].salse = (item.price*(price/100+1)).toFixed(2);
        }
      })
    },
    // 取消改变价格
    cancelbatchmprice() {
      this.batchMprice = 0;
    },
    // 改变售价
    salsePriceChange(val, detail) {
      console.log(val, detail);
      let price = 0;
      price = this.list[this.active].list[detail.name].price;
      let mprice = 0;
      mprice = parseFloat((val - price));
      mprice = mprice.toFixed(2);
      mprice = parseFloat(mprice);
      this.list[this.active].list[detail.name].mprice = mprice;
    }
  }
}
</script>
<style lang="less">
.goodsedit {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .van-nav-bar__right {
    .van-button {
      padding: 0 16px;
      height: 30px;
      font-size: 14px;
    }
  }
  .goods_title {
    .van-cell__title {
      flex: 1;
      text-align: left;
    }
  }
  .main {
    width: 100%;
    overflow: hidden;
    padding-bottom: 60px;
    .sku_main {
      overflow: hidden;
      .batch_edit {
        background-color: #f0f0f0;
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        .van-button {
          background-color: #f0f0f0;
          padding: 0 10px;
          line-height: 24px;
          height: 25px;
        }
      }
      .edit_title {
        .van-cell__title {
          flex: 1;
          text-align: left;
          overflow: hidden;
          margin-right: 10px;
        }
        .van-cell__value {
          color: #333;
          margin: 0 10px;
          text-align: center;
          min-width: 46px;
        }
        .merch_jiajia {
          margin-left: 10px;
          min-width: 100px;
          text-align: center;
        }
      }
    }
    .van-dialog {
      .van-dialog__content {
        padding-top: 10px;
        .van-cell {
          .van-cell__title{
            flex: 1;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
          }
          .van-cell__value {
            flex: 1;
            text-align: left;
            font-size: 14px;
          }
          .van-cell__label {
            font-weight: normal;
            font-size: 14px;
          }
          &:first-of-type {
            .van-cell__title {
              flex: 1;
              text-align: center;
              overflow: hidden;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .red {
    color: #ee0a24;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>